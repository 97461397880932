import './index.scss'
import React, {useEffect} from "react"
// @ts-ignore
import Layout from "../../../components/layout"
// @ts-ignore
import Seo from "../../../components/seo"

import pathJson from "../../../data/common/path.json"

import CreatorBenefitsPage from "./Components/creatorBenefits"
import CreatorFAQPage from "./Components/creatorFAQ"

const CreatorPage = () => {


    useEffect(() => {
        
    }, []);


    return (
        <Layout>
            <Seo title={pathJson.CREATOR_BENEFITS.title} metaDescription={'创作者兼职'} keywords={pathJson.CREATOR_BENEFITS.keywords}/>
            <>
               <CreatorBenefitsPage></CreatorBenefitsPage> 
               <CreatorFAQPage></CreatorFAQPage>
            </>
        </Layout>
    )
}

export default CreatorPage;