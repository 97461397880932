import React, { useEffect } from "react";
import { BuryingPointFc } from "../../../../components/buryingPoint";
import './creatorFAQ.scss';

const CreatorFAQPage: React.FC = () => {

  useEffect(() => {
    const items = document.querySelectorAll(".creator_faq_accordion button");

    function toggleAccordion(this: HTMLElement) {
      const itemToggle = this.getAttribute('aria-expanded');
      
      items.forEach(item => item.setAttribute('aria-expanded', 'false'));

      if (itemToggle === 'false') {
        this.setAttribute('aria-expanded', 'true');
      }
    }

    items.forEach(item => item.addEventListener('click', function() {
        // @ts-ignore
      toggleAccordion.call(this);
    }));

    // Cleanup function to remove event listeners
    return () => {
      items.forEach(item => item.removeEventListener('click', function() {
        // @ts-ignore
        toggleAccordion.call(this);
      }));
    };
  }, []); // Empty dependency array ensures this effect runs once on mount

  return (
    <div className="creator_faq_container">
      <h2 className='text-center mb-4'>常见问答</h2>
      <div className="creator_faq_accordion">
        <div className="creator_faq_accordion-item">
          <button id="accordion-button-1" aria-expanded="false" onClick={()=>BuryingPointFc('VO','VO00300100101001',{action:'作为创作者，我如何获得订单？'})}>
            <span className="accordion-title">Q1: 作为创作者，我如何获得订单？</span>
            <span className="icon" aria-hidden="true"></span>
          </button>
          <div className="accordion-content">
            <p>{'先在手机应用市场下载里“德笔记APP”，按照提示注册账户。通过 “里德笔记App” > “频道” ，加入您感兴趣的频道，或者通过右上角的“扫一扫”，通过扫码加入频道。商家管理员审核通过后，您即可在"发现"栏(或者"频道">“任务”)领取相关任务。完成并提交任务 > 等待审核通过，即可自助提现。'}</p>
          </div>
        </div>
        <div className="creator_faq_accordion-item">
          <button id="accordion-button-2" aria-expanded="false" onClick={()=>BuryingPointFc('VO','VO00300100101002',{action:' 领取任务时，为何提示我需要入驻平台?'})} >
            <span className="accordion-title">Q2: 领取任务时，为何提示我需要入驻平台?</span>
            <span className="icon" aria-hidden="true"></span>
          </button>
          <div className="accordion-content">
            <p>              1. 平台公信力和公平的一份保障，避免乱接乱发等情况的发生<br></br>
              2. 权益保障，例如当你遇到恶意商家后可申诉等权利<br></br>
              3. 专人辅导，在你入驻之后将会有专人辅导，带你快速上手<br></br>
              创作者缴纳入驻手续费并正式入驻后，方可接收派单或抢单<br></br></p>
          </div>
        </div>
        <div className="creator_faq_accordion-item">
          <button id="accordion-button-3" aria-expanded="false" onClick={()=>BuryingPointFc('VO','VO00300100101003',{action:' 作为创作者，我如何提现?”'})}>
            <span className="accordion-title">Q3: 作为创作者，我如何提现?</span>
            <span className="icon" aria-hidden="true"></span>
          </button>
          <div className="accordion-content">
            <p><span><b>自助提现</b></span>
              <br></br><br/>
              Web端操作步骤：登录 - 首页 - 点击"前往提现" - 填写信息 即可
              <br></br>
              APP端操作步骤：登录 - 我的 - 点击"提现" - 填写信息 即可</p>
          </div>
        </div>
        <div className="creator_faq_accordion-item">
          <button id="accordion-button-4" aria-expanded="false" onClick={()=>BuryingPointFc('VO','VO00300100101004',{action:' 作为创作者，我的收益从哪里来？'})}>
            <span className="accordion-title">Q4: 作为创作者，我的收益从哪里来？</span>
            <span className="icon" aria-hidden="true"></span>
          </button>
          <div className="accordion-content">
            <p>派单: 当你成功入驻自媒体博主后，你的自媒体账号信息将会被展示在平台，有机会被商家看到并被派单。<br></br>
              抢单: 当你加入频道后，你可以领取你擅长的任务并完成获取收益<br></br>
              裂变: 当前每邀请一位创作者成功入驻，可以获得15.00元返利<br></br></p>
          </div>
        </div>
        <div className="creator_faq_accordion-item">
          <button id="accordion-button-5" aria-expanded="false" onClick={()=>BuryingPointFc('VO','VO00300100101005',{action:'邀请创作者入驻平台，如何计算收益?'})}>
            <span className="accordion-title">Q5: 邀请创作者入驻平台，如何计算收益?</span>
            <span className="icon" aria-hidden="true"></span>
          </button>
          <div className="accordion-content">
            <p>当前每邀请一位创作者成功入驻，可以获得15.00元返利。</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatorFAQPage;
