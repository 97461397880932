import './index.css'

import React from "react"
// @ts-ignore
import creator_benefits_backgroud from '../../../../assets/images/mobile/creator_benefits_backgroud.png';
import { APP_DOWNLOAD_URL } from '../../../../commons/constant/constants';

const isPhone = () => {
    if (typeof window !== `undefined`) {
      //判断是不是移动端
      const result = (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i));
      if (result) {
        // 移动端
        return true;
      }
      else {
        //PC端页面
        return false
      }
    }
  }

const CreatorBenefitsPage = () => {
    return (
        // style={{ backgroundImage: `url(${creator_benefits_backgroud})` }}
        <div className="creator_benefits_main">
            <div className="content">
                <div className="text-container">
                    {!isPhone() ? <h1>创作者中心</h1> : <h1>里德笔记APP</h1>}
                
                <p className="par">用空闲的时间 在线提交任务赚取收益 <br/> <b>限时免费中! </b> 2024年9月下旬开始需要缴纳19.99/年的平台入驻手续费</p>
                {!isPhone() ? <button className="cn" onClick={()=>{window.location.href = "http://c.lidebiji.com/"}}>立即注册</button> : <button className="cn" onClick={()=>{window.location.href = APP_DOWNLOAD_URL}}>立即下载</button>}
                
                </div>

            </div>
        </div>



    )
}

export default CreatorBenefitsPage;